import React from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import styled from "styled-components"
import { Link } from "gatsby"
import { keyframes } from "styled-components"

function TradeinPage() {
  return (
    <Layout>
      <SEO title="Past works" />
      <Wrapper>
        <CloseBtn>
          <Link to="/">
            <img src="images/icons/cancel.png" alt="cancel"></img>
          </Link>
        </CloseBtn>
        <ContentWrapper>
          <Bg1>
            <ContentHeaderWrapper> Trade-in Device</ContentHeaderWrapper>
          </Bg1>
        </ContentWrapper>
        <Bg1>
          <ContentBodyWrapper>
            <Header>Overview</Header>
            <Body>
              I was part of a trade-in program for 2017 to design online
              trade-in experience. In this project, I led the UX design from the
              start to the end. Based on last year's trade-in promotion
              feedback, I approached the design thinking to improve user pain
              points. The results of my design points are:
            </Body>
            <Body>
              1. Tell users whether their device is eligible for trade-in ASAP.
            </Body>
            <Body>2. Suggest right promotion to qualified target users.</Body>
            <Body>
              3. Deliver clear instruction on how the user receives trade-in
              credits and how shown in the cart.
            </Body>
            <Body>
              4. Allow users to use trade-in at all entry points (I have omitted
              this part due to confidential information).
            </Body>
          </ContentBodyWrapper>
        </Bg1>

        <Bg2>
          <ContentBodyWrapper>
            <Header>Design Process & My Role</Header>
            <Body>
              In this project, I led the UX design from the start to the end.
              Since T-Mobile planned the promotion through trade-in program, I
              worked with the marketing team. Also, work closely with assurance
              team to provide trade-in value to users quickly and smoothly.
            </Body>
            <img
              src="images/past/designprocess1.png"
              alt="designprocess1"
            ></img>
          </ContentBodyWrapper>
        </Bg2>

        <Bg1>
          <ContentBodyWrapper>
            <Header>
              Define problem:{" "}
              <span>Understand users pain points from previous project.</span>
            </Header>
            <Body>
              According to the data provided by the program manager, about 73.3%
              of online users were not able to receive trade-in credits
              successfully in 2016. There were a lot of reasons including user
              returned the iPhone without turning off the find my iPhone
              feature. Users were also confused with how they receive their
              trade-in credits. While promotion credits immediately applied to
              cart, standard trade-in credits applied to their next bill. These
              pain points resulted in increased care calls and customer
              dissatisfaction
            </Body>
            <img src="images/past/marketing1.png" alt="marketing1"></img>
          </ContentBodyWrapper>
        </Bg1>

        <Bg2>
          <ContentBodyWrapper>
            <Header>
              The Challenge:{" "}
              <span>Create the scenario to meet all business requirements</span>
            </Header>
            <Body>
              The biggest challenge was to prepare for the promotion without
              knowing the specs of the new iPhone. In fact, PM and I built many
              virtual scenarios to meet the business requirement from the
              various team.
            </Body>
            <img src="images/past/whiteboard1.png" alt="whiteboard1"></img>
            <Body>
              So, PM has arranged those documents, and I created two major types
              of scenarios: standard trade-in and iPhone promotion. Then I
              created the persona and moved onto IA and wireframe.
            </Body>
            <img src="images/past/challenge1.png" alt="callenge1"></img>
          </ContentBodyWrapper>
        </Bg2>

        <Bg1>
          <ContentBodyWrapper>
            <Header>
              Design Approach 1:
              <span>
                {" "}
                Tell users whether their device is eligible for trade-in ASAP
              </span>
            </Header>
            <Body>
              With the current trade-in quote tool, the user had to select the
              carrier, make, model, and enter IMEI to determine if the device
              has no issue with trade-in. But it's IMEI number that assurance
              team needs to check. So I was trying to find a way to improve and
              reduce the steps for users.
            </Body>
            <Body>
              While I worked with the assurance team, I found out that the IMEI
              number alone could pull up the information of a carrier, make, and
              model name for the device (about 80% accuracy). So I designed
              quote tool that asking the user to put IMEI first.
            </Body>
            <img
              src="images/past/designApproach1.png"
              alt="design approach"
            ></img>
            <Body>
              By dialing * # 06 # on your phone, the user could find IMEI number
              for the device. I thought something visual element would help user
              since the most of people are not familiar with where to find IMEI
              number. So I created animation and applied to Framer by using
              Lottie.
            </Body>
            <FramerContainer>
              <img src="images/past/framer1.png" alt="Framer"></img>
              <Framer>
                <img src="images/past/tqt2.gif" alt="Trade In"></img>
              </Framer>
            </FramerContainer>
          </ContentBodyWrapper>
        </Bg1>

        <Bg2>
          <ContentBodyWrapper>
            <PromoPage>
              <PromoWord>
                <Header>
                  Design Approach 2:
                  <span> Always provide the best trade-in value to users</span>
                </Header>
                <Body>
                  Promotional banners are not enough to convey enough knowledge
                  to users. I have designed the trade-in quote tool to provide
                  promotions to the right users for promotions. All user has to
                  do is making a choice.
                </Body>
              </PromoWord>
              <img src="images/past/promo1.png" alt="promo"></img>
            </PromoPage>
          </ContentBodyWrapper>
        </Bg2>

        <Bg1>
          <ContentBodyWrapper>
            <Header>
              Design Approach 3:
              <span>
                {" "}
                Deliver clear instruction on how the user receives trade-in
                credits and how shown in the cart.
              </span>
            </Header>
            <Body>
              From the data, many users thought that they could get trade-in
              credits right away so they went to cart to check their total
              price. However, the standard trade-in would be credited to the
              next bill cycle when the user successfully returned the phone to
              assurance team. So, I explained how to get trade-in instruction
              and receive credits on trade-in confirmation page and cart page.
            </Body>
            <img
              src="images/past/designApproach3.png"
              alt="Design Approach 3"
            ></img>
          </ContentBodyWrapper>
        </Bg1>

        <Bg2>
          <ContentBodyWrapper>
            <Header>Iteration</Header>
            <Body>
              The project timeline changed because the company decided to launch
              the trade-in service with the launch of Galaxy 8. Thus, the design
              and scope of the project have modified:
            </Body>
            <Body>
              1. Tell users whether their device is eligible for trade-in ASAP
              (Delivered MVP version)
            </Body>
            <Body>
              2. Suggest right promotion to qualified target users (Delivered
              MVP version)
            </Body>
            <Body>
              3. Deliver clear instruction on how the user receives trade-in
              credits and how shown in the cart (Delivered)
            </Body>
            <Body>
              4. Allow users to use trade-in at all entry points (Delivered)
            </Body>

            <Body>
              With final delivery, If the user checks the phone value in the TQT
              page, on the next page lists the promotion options available to
              the user.In October (2017), T-Mobile offered $300 to any customer
              who trades in an iPhone 6 or newer in good condition for an iPhone
              8, iPhone 8 Plus, or the iPhone X.
            </Body>
            <img src="images/past/finalHandoff.png" alt="Final Handoff"></img>
          </ContentBodyWrapper>
        </Bg2>

        <Bg1>
          <ContentBodyWrapper>
            <Header>Result</Header>
            <Body>
              The trade-in device service launched with Samsung Galaxy 8 in July
              2017, and we got 3.8% of market share of the trade-in service.
              When iPhone 8 launched in October 2017, we had almost doubled the
              number of the market share (6.67%).
            </Body>
            <img src="images/past/result1.png" alt="Result"></img>
          </ContentBodyWrapper>
        </Bg1>

        <Bg3>
          <ContentMenuWrapper>
            <InActiveMenu>
              <span role="img" aria-label="next">
                ⬅
              </span>
              <span> Previous project</span>
            </InActiveMenu>
            <Link to="/changeplan">
              <Menu>
                Next project{" "}
                <span role="img" aria-label="next">
                  ⮕
                </span>
              </Menu>
            </Link>
          </ContentMenuWrapper>
        </Bg3>
      </Wrapper>
    </Layout>
  )
}

export default TradeinPage

const animation = keyframes`
from {opacity: 0; transform: translateY(10px);};
to {opacity: 1; transform: translateY(0px);};
`

const Wrapper = styled.div`
  width: 100%;
`

const ContentWrapper = styled.div`
  background: linear-gradient(rgba(19, 0, 9, 0.7), rgba(255, 0, 124, 0.78)),
    url("/images/past/tradein.png");
  height: 600px;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: end;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 944px) {
    height: 400px;
  }
`
const ContentHeaderWrapper = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 72px;
  line-height: 100%;
  color: #fff;
  padding-bottom: 86px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  animation: ${animation} 1s 0.2s forwards;
  opacity: 0;
`

const ContentBodyWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  img {
    max-width: 100%;
    height: 100%;
    margin-bottom: 24px;
  }
`

const ContentMenuWrapper = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
`

const Bg1 = styled.div`
  width: 100%;
  padding: 86px 200px;

  @media (max-width: 944px) {
    padding: 24px;
  }
`

const Bg2 = styled.div`
  width: 100%;
  padding: 86px 200px;
  background-color: #fff;

  @media (max-width: 944px) {
    padding: 24px;
  }
`

const Bg3 = styled.div`
  width: 100%;
  padding: 86px 200px;
  background-color: #fff;

  @media (max-width: 944px) {
    padding: 48px;
    height: 160px;
  }
`

const Header = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 36px;

  line-height: 130%;
  /* or 31px */

  color: #f50075;
  margin-bottom: 24px;

  span {
    color: #000;
  }
`

const Body = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 150%;
  /* or 23px */

  /* Text */

  color: #000000;
  margin-bottom: 24px;
`

const FramerContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const Framer = styled.div`
  width: 300px;
  height: 300px;
  position: absolute;
  border-radius: 500px;
  overflow: hidden;
  box-shadow: 0 5px 22px 0 rgb(0 0 0 / 20%), 0 -6px 21px 0 rgb(0 0 0 / 19%);
  margin-top: 250px;
  margin-right: 32px;

  @media (max-width: 944px) {
    width: 150px;
    height: 150px;
    margin-top: 160px;
  }
`

const PromoPage = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 944px) {
    display: block;
  }
`
const PromoWord = styled.div`
  max-width: 554px;
  margin-right: 64px;
`

const InActiveMenu = styled.div`
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */
  opacity: 0.2;
  pointer-events: none;
`

const Menu = styled.div`
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */

  color: #2d577d;

  :hover {
    opacity: 0.8;
  }
`

const CloseBtn = styled.div`
  display: block;
  position: fixed;
  margin: 35px 30px;

  :hover {
    opacity: 0.4;
  }

  @media (max-width: 944px) {
    display: none;
  }

  img {
    width: 50px;
    height: 50px;
    opacity: 0.7;
  }
`
